import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_PARTNER_URL } from '../../connection.data';

@Injectable()
export class PartnerService extends BasicService {
    partner = null;
    token: string|null = null;
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_PARTNER_URL;
    }

    auth(pass: string){
        return this.http.post(`${this.baseUrl}/auth`, {
            late_assignation_pass: pass
        })
    }

    setPartner(partner: any){
        this.partner = partner;
    }

    getPartner(){
        return this.partner;
    }

    setToken(token: string){
        this.token = token;
    }

    getToken(){
        return this.token;
    }
}